body,
h1 {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: #3a4570;
}

button.btn-link {
  border: none;
  background: none;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
}
